@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}

:root {
  --n: 0px;
  --xs: 8px;
  --s: 10px;
  --m: 20px;
  --l: 25px;
  --xl: 61px;

  --fxs: 12px;
  --fs: 16px;
  --fm: 36px;

  --primary-color: #1d70d3;
  --grey: #ebebeb;
}

.fxs {
  font-size: var(--fxs);
}

.fs {
  font-size: var(--fs);
}

.fm {
  font-size: var(--fm);
}

.mn {
  margin: var(--n);
}

.mxs {
  margin: var(--xs);
}

.ms {
  margin: var(--s);
}

.mm {
  margin: var(--m);
}

.ml {
  margin: var(--l);
}

.mxl {
  margin: var(--xl);
}

.mln {
  margin-left: var(--n);
}

.mls {
  margin-left: var(--s);
}

.mlm {
  margin-left: var(--m);
}

.mlxl {
  margin-left: var(--xl);
}

.mrn {
  margin-right: var(--n);
}

.mrs {
  margin-right: var(--s);
}

.mtn {
  margin-top: var(--n);
}

.mts {
  margin-top: var(--s);
}

.mtm {
  margin-top: var(--m);
}

.mtl {
  margin-top: var(--l);
}

.mtxl {
  margin-top: var(--xl);
}

.mbn {
  margin-bottom: var(--n);
}

.mbs {
  margin-bottom: var(--s);
}

.mbm {
  margin-bottom: var(--m);
}

.mbl {
  margin-bottom: var(--l);
}

.mbxl {
  margin-bottom: var(--xl);
}

.pn {
  padding: var(--n);
}

.ps {
  padding: var(--s);
}

.pbn {
  padding-bottom: var(--n);
}

.pbs {
  padding-bottom: var(--s);
}

.prn {
  padding-right: var(--n);
}

.prs {
  padding-right: var(--s);
}

.prm {
  padding-right: var(--m);
}

.no-border {
  border: var(--n);
}

.grey {
  background-color: var(--grey);
  color: #b3b3b3;
}

.rounded {
  border-radius: 6px;
}

.bold {
  font-weight: 700;
}

.font-azul {
  color: var(--primary-color);
}

.font-white {
  color: #ffffff !important;
}

.bg-azul {
  background-color: var(--primary-color);
}

.bg-azul-claro {
  background-color: #44ccff;
}

/* ANTD THEME MODIFIERS */

.ant-input {
  background-color: #ebebeb;
}

input:-internal-autofill-selected {
  background-color: #ebebeb !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #154c8e;
}

.ant-tooltip-inner {
  text-align: center;
}

.ant-select-selector {
  border-radius: 6px !important;
  background-color: var(--grey) !important;
}

@media (max-width: 500px) {
  .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column;
  }
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper {
    place-content: center;
  }
  .ant-picker-range-arrow {
    left: initial !important;
  }
}
